<template>
  <div>
    <vxe-form :data="formData" @submit="submitEvent">
      <vxe-form-item field="code" :item-render="{}">
        <template>
          <vxe-input
            size="medium"
            v-model="formData.businessCode"
            placeholder="请输入业务类型编码"
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item field="name" :item-render="{}">
        <template>
          <vxe-input
            size="medium"
            v-model="formData.businessName"
            placeholder="请输入业务类型名称"
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item style="float: right">
        <template>
          <vxe-button icon="el-icon-search" size="medium" type="submit" status="primary"
            >查询</vxe-button
          >
          <vxe-button icon="el-icon-refresh" size="medium" type="reset">重置</vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
    <div style="margin: 10px 0">
      <el-button type="primary" @click="addRow">新增一行</el-button>
    </div>
    <vxe-table  v-loading="tableLoading" border :data="tableData">
      <vxe-table-column field="businessCode" title="业务类型编码">
        <template v-slot="{ row }">
          <el-input v-if="row.isEdit" v-model="row.businessCode" />
          <span v-else>{{row.businessCode}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="businessName" title="业务类型名称">
        <template v-slot="{ row }">
          <el-input v-if="row.isEdit" v-model="row.businessName" />
          <span v-else>{{row.businessName}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="controlFlag" title="启用状态">
        <template v-slot="{ row }">
          <el-switch @change="(e)=>switchChange(row,e)" v-model="row.controlFlag" />
        </template>
      </vxe-table-column>
      <vxe-table-column  title="操作">
        <template v-slot="{ row,rowIndex }">
           <el-link type="primary" v-if="row.isEdit" @click="rowAdd(row,rowIndex)">添加</el-link>
           <el-link type="primary" v-else @click="approval(row,rowIndex)">配置</el-link>
           <el-link type="primary" @click="rowDel(row,rowIndex)">删除</el-link>
        </template>
      </vxe-table-column>
    </vxe-table>
    <Modal :modalConfig.sync="modalConfig"
           @onClose="closeModal"
           :visible.sync="modalConfig.visible">
      <component :is="formName"
                 ref="modalForm"
                 @onClose="closeModal"
                 @onGetList="getList"
                 :formConfig="formConfig"/>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../../../components/modal';
import Form from './form/form.vue';
import request from '../../../../../utils/request';

export default {
  components: {
    Modal,
    Form,
  },
  props: {
    parentCode: String,
    functionPermission: String,
  },
  watch: {
    parentCode(n) {
      this.formConfig.parentCode = n;
      this.getList();
    },
  },
  data() {
    return {
      modalConfig: {
        visible: false,
        title: '配置',
        type: 'Full',
      },
      formConfig: {

      },
      tableData: [],
      tableDataItem: {
        businessCode: '',
        businessName: '',
        controlFlag: true,
        isEdit: true,
      },
      formData: {
        businessCode: '',
        businessName: '',
      },
      formName: 'Form',
      tableLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    submitEvent(e) {
      this.getList();
    },
    /**
     * 改变状态
     */
    switchChange(row, e) {
      let apiUrl = '';
      if (e) {
        apiUrl = '/mdm/mdmApprovalConfigController/open';
      } else {
        apiUrl = '/mdm/mdmApprovalConfigController/close';
      }
      request.post(apiUrl, [row.id]).then((res) => {
        if (res.success) {
          this.$message.success('操作成功');
          this.getList();
        }
      }).catch(() => {
        this.getList();
      });
    },
    /**
     * 新增一行
     */
    addRow() {
      if (!this.parentCode) {
        this.$message.error('请先选择菜单');
        return false;
      }
      this.tableData.unshift(this.tableDataItem);
    },
    /**
     * 添加
     */
    rowAdd(row, index) {
      request.post('/mdm/mdmApprovalConfigController/save', {
        ...row,
        controlFlag: row.controlFlag ? '1' : '0',
        menuCode: this.parentCode,
      }).then((res) => {
        if (res.success) {
          this.getList();
        }
      });
    },
    /**
     * 删除
     */
    rowDel(row, index) {
      this.$confirm('是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.post('/mdm/mdmApprovalConfigController/delete', [row.id]).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.getList();
            }
          });
        });
    },
    /**
     * 配置
     */
    approval(row, index) {
      this.modalConfig.visible = true;
      this.formConfig.id = row.id;
      this.formConfig.code = 'edit';
      this.formConfig.menuCode = this.parentCode;
      this.formConfig.businessCode = row.businessCode;
      this.formConfig.businessName = row.businessName;
      this.formConfig.controlFlag = row.controlFlag;
    },
    /**
     * 关闭弹窗
     */
    closeModal() {
      this.modalConfig.visible = false;
    },
    /**
     * 获取列表数据
     */
    getList() {
      console.log(this.parentCode);
      this.tableLoading = true;
      if (!this.parentCode) return;
      request.post('/mdm/mdmApprovalConfigController/list', {
        menuCode: this.parentCode,
        ...this.formData,
      }).then((res) => {
        this.tableLoading = false;
        if (res.success) {
          this.tableData = res.result.map((item) => ({
            ...item,
            controlFlag: item.controlFlag === '1',
          }));
        }
      }).catch(() => {
        this.tableLoading = false;
      });
    },
  },
};
</script>

<style></style>
