<template>
  <div class="container">
    <Table ref="table" :propsObj="propsObj"/>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit" size="small" icon="el-icon-check">提交 </el-button>
      <el-button type="danger" @click="closeModal" size="small" icon="el-icon-close"
        >关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import Table from './table.vue';
import request from '../../../../../utils/request';

export default {
  components: {
    Table,
  },
  props: {
    propsObj: Object,
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      const { tableData } = this.$refs.table;
      console.log(tableData);
      const { selection } = this.$refs.table;
      console.log(selection);
      tableData.map((v) => {
        const item = v;
        if (selection.includes(item.permissionObjCode)) {
          item.select = '1';
        } else {
          item.select = '0';
        }
        return item;
      });
      const params = {
        functionCode: this.propsObj.functionCode,
        parentCode: this.propsObj.parentCode,
        mdmPermissionObjRespVos: tableData,
      };
      request.post('/mdm/mdmfunctionsub/saveObj', params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
        }
      });
    },
    closeModal() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
}
</style>
