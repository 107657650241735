<script>
import TableForm from '../../../../../../components/table_form';
import Form from '../../next_form';

import request from '../../../../../../utils/request';

const isVisible = [
  {
    value: true,
    label: '否',
  },
  {
    value: false,
    label: '是',
  },
];

const isReqire = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];

const isSearch = [
  {
    value: 'true',
    label: '是',
  },
  {
    value: 'false',
    label: '否',
  },
];
const isView = [
  {
    value: 'true',
    label: '是',
  },
  {
    value: 'false',
    label: '否',
  },
];
const isExport = [
  {
    value: '1',
    label: '是',
  }, {
    value: '0',
    label: '否',
  },
];
const typeListForm = [
  {
    value: 'input',
    label: '输入框',
  },
  {
    value: 'select',
    label: '下拉框',
  },
  {
    value: 'multi_select',
    label: '下拉框多选',
  },
  {
    value: 'FormSelectTree',
    label: '树形下拉框(不建议使用)',
  },
  {
    value: 'FormSelectTreeNew',
    label: '树形下拉框(单选)',
  },
  {
    value: 'FormSelectTreeNewMultiple',
    label: '树形下拉框(多选)',
  },
  {
    value: 'inputNumber',
    label: '数字输入框',
  },
  {
    value: 'radio',
    label: '单选框',
  },
  {
    value: 'checkbox',
    label: '复选框',
  },
  {
    value: 'custom',
    label: '自定义控件',
  },
  {
    value: 'textarea',
    label: '多行文本框',
  },
  {
    value: 'switch',
    label: '开关',
  },
  {
    value: 'cascader',
    label: '多级联动',
  },
  {
    value: 'DatePicker',
    label: '日期选择器',
  },
  {
    value: 'TimePicker',
    label: '时间选择器',
  },
  {
    value: 'upload',
    label: '上传',
  },
  {
    value: 'fileUpload',
    label: '上传文件',
  },

  {
    value: 'tree',
    label: '树形',
  },
  {
    value: 'FormEdit',
    label: '富文本',
  },
  {
    value: 'formHeader',
    label: '标题',
  },
];
const typeListTable = [
  {
    value: 'input',
    label: '输入框',
  },
  {
    value: 'select',
    label: '下拉框',
  },
  {
    value: 'DateTimePicker',
    label: '日期时间选择器',
  },
  {
    value: 'DatePicker',
    label: '日期选择器(年月日)',
  },
  {
    value: 'DatePickerMonth',
    label: '日期选择器(年月)',
  },
  {
    value: 'TimePicker',
    label: '时间选择器',
  },
  {
    value: 'customSearch',
    label: '自定义',
  },
];

const columnsTable = [
  {
    field: 'field',
    title: '显示属性',
    fixed: 'left',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'title',
    title: '标题名称',
    fixed: 'left',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'visible',
    title: '显隐状态',
    editRender: {
      name: 'elSwitch',
      options: isVisible,
    },
  },
  {
    field: 'editView',
    title: '是否点击查看',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: 'true',
        inactiveValue: 'false',
      },
    },
  },
  {
    field: 'columnExport',
    title: '是否导出',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: '1',
        inactiveValue: '0',
      },
    },
  },
  {
    field: 'width',
    title: '显示宽度',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'search',
    title: '是否查询',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: 'true',
        inactiveValue: 'false',
      },
    },
  },
  {
    field: 'type',
    title: '搜索控件类型',
    editRender: {
      name: 'elSelect',
      options: typeListTable,
    },
  },
  {
    field: 'formControl',
    title: '表单控件类型',
    editRender: {
      name: 'elSelect',
      options: typeListForm,
    },
  },
  // {
  //   field: 'required',
  //   title: '是否必填',
  //   editRender: {
  //     name: 'elSelect',
  //     options: isReqire,
  //   },
  // },
  {
    field: 'dictCode',
    title: '数据字典',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'formorder',
    title: '显示顺序',
    width: '140',
    editRender: {
      name: 'elInputNumber',
    },
  },
  {
    title: '操作',
    width: '120',
    align: 'center',
    fixed: 'right',
    type: 'buttom',
    buttons: [
      {
        type: 'dropdown',
        buttonType: 'link',
        code: 'delete',
        name: '删除',
      },
    ],
  },
];

const columnsForm = [
  {
    field: 'field',
    title: '显示属性',
    fixed: 'left',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'title',
    title: '标题名称',
    fixed: 'left',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'required',
    title: '是否必填',
    editRender: {
      name: 'elSwitch',
      options: isReqire,
    },
  },
  {
    field: 'visible',
    title: '显隐状态',
    editRender: {
      name: 'elSwitch',
      options: isVisible,
    },
  },
  {
    field: 'editableInCreate',
    title: '新增时是否可编辑',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: 'true',
        inactiveValue: 'false',
      },
    },
  },
  {
    field: 'editableInEdit',
    title: '编辑时是否可编辑',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: 'true',
        inactiveValue: 'false',
      },
    },
  },
  {
    field: 'visibleInEdit',
    title: '编辑时是否显示',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: 'true',
        inactiveValue: 'false',
      },
    },
  },
  {
    field: 'visibleInLook',
    title: '查看时是否显示',
    editRender: {
      name: 'elSwitch',
      props: {
        activeValue: 'true',
        inactiveValue: 'false',
      },
    },
  },
  // {
  //   field: 'editView',
  //   title: '是否点击查看',
  //   editRender: {
  //     name: 'elSelect',
  //     options: isView,
  //   },
  // },
  {
    field: 'type',
    title: '控件类型',
    editRender: {
      name: 'elSelect',
      options: typeListForm,
    },
  },
  {
    field: 'dictCode',
    title: '数据字典',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'customName',
    title: '自定义组件名称',
    editRender: {
      name: 'elInput',
    },
  },
  {
    field: 'width',
    width: '140',
    title: '控件宽度',
    editRender: {
      name: 'elInputNumber',
      props: {
        max: 24,
      },
    },
  },
  {
    field: 'formorder',
    title: '显示顺序',
    width: '140',
    editRender: {
      name: 'elInputNumber',
    },
  },
  {
    title: '操作',
    width: '120',
    align: 'center',
    fixed: 'right',
    type: 'buttom',
    buttons: [
      {
        type: 'dropdown',
        buttonType: 'link',
        code: 'delete',
        name: '删除',
      },
    ],
  },
];
const tableConfig = {
  border: true,
  idKey: 'id', // 默认勾选判断字段
  resizable: true, // 所有的列是否允许拖动列宽调整大小
  showOverflow: true, // 当内容过长时显示为省略号
  keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
  highlightHoverRow: true, // 鼠标滑动高亮
  trigger: 'click',
  // height: '300',
};

const toolBarConfig = [
  {
    type: 'button',
    // buttonType: 'primary',
    // icon: 'el-icon-plus',
    code: 'add',
    name: '新增',
  },
  {
    type: 'button',
    buttonType: null,
    // icon: 'el-icon-search',
    name: '选择服务源',
    code: 'view',
  },
];

export default {
  extends: TableForm,
  components: {
    Form,
  },
  props: {
    functionCode: String,
    functionType: String,
  },
  data() {
    return {
      tableData: [],
      formConfig: {},
      isCalculateHeight: true,
      tableHeight: window.innerHeight - 230,
      screenHeight: window.innerHeight,
      configs: {
        // 配置信息
        columns: [], // 列信息
        tableConfig, // 表信息
        toolBarConfig,
      },
    };
  },
  created() {},
  watch: {
    functionType(n) {
      this.configs.columns = n === '1' ? columnsTable : columnsForm;
    },
    screenHeight(val) {
      console.log(val);
      this.tableHeight = val - 230;
    },
  },
  mounted() {
    window.onresize = () => (() => {
      window.screenHeight = window.innerHeight;
      this.screenHeight = window.screenHeight;
    })();
  },
  methods: {
    onChange({
      val, item, rowIndex, row,
    }) {
      return {
        val,
        item,
        rowIndex,
        row,
      };
    },
    onInput({
      val, item, rowIndex, row,
    }) {
      return {
        val,
        item,
        rowIndex,
        row,
      };
    },
    modalClick({ val }) {
      this.formConfig.functionCode = this.functionCode;
      if (val.code === 'add') {
        if (this.functionType === '1') { // 表格
          this.value.tableData.push({
            visible: true,
            columnExport: '1',

          });
        } else {
          this.value.tableData.unshift({
            visible: true,
            editableInCreate: 'true',
            editableInEdit: 'true',
            visibleInLook: 'true',
            visibleInEdit: 'true',
          });
        }
        // 给默认顺序
        // this.value.tableData.forEach((item, index) => {
        //   item.formorder = index + 1;
        // });
        // this.insertEvent();
      } else if (val.code === 'view') {
        const selection = [];
        // 将当前页面的选中项的id传给下一个表单
        this.value.tableData.forEach((v) => {
          selection.push(v.field);
        });
        this.formConfig.selection = selection;
        this.openModal();
      }
    },
    async insertEvent(row) {
      const { row: newRow } = await this.$refs.xTable.insertAt({}, row);
      await this.$refs.xTable.setActiveCell(newRow, 'field');
    },
    messageBox(data, row, rowIndex) {
      let configData = data;
      configData = {
        ...data,
        title: '此操作将永久删除该字段, 是否继续?',
        url: '/mdm/mdmcolumnconfig/delete',
      };
      this.$confirm(configData.title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.post(configData.url, { ...configData.params, functionCode: this.functionCode }).then((res) => {
            if (res.success) {
              this.value.tableData.splice(rowIndex, 1);
              this.$message({
                type: 'success',
                message: `${configData.msg}成功`,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `已取消${configData.msg}`,
          });
        });
    },
    // 点击表格触发编辑模式
    editActivedEvent({ rowIndex, row, columnIndex }) {
      // 假设第一行的字段为functionName的数据不予许编辑
      this.configs.columns.map((v) => {
        const item = v;
        item.editRender = {
          ...item.editRender,
        };
        if (
          row.type !== 'select'
          && row.type !== 'radio'
          && row.type !== 'checkbox'
          && row.type !== 'FormSelectTree'
          && row.type !== 'customSearch'
          && item.field === 'dictCode'
        ) {
          item.editRender.props = {
            ...item.editRender.props,
            disabled: true,
          };
        } else if (row.type !== 'custom' && item.field === 'customName') {
          item.editRender.props = {
            ...item.editRender.props,
            disabled: true,
          };
        } else {
          item.editRender.props = {
            ...item.editRender.props,
            disabled: false,
          };
        }
        return item;
      });

      return { rowIndex, row, columnIndex };
    },
  },
};
</script>
