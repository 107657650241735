<template>
  <div class="list-box">
    <div class="main left home">
      <MenuPage @changeParentCode="changeParentCode"/>
    </div>
    <div class="main right home">
       <el-tabs v-model="activeName">
    <el-tab-pane label="页面配置" name="page">
      <div class="table">
      <TablePage :parentCode="parentCode" :functionPermission ="functionPermission"/>
      </div>
    </el-tab-pane>
    <el-tab-pane label="审批配置" name="approval">
      <ApprovalPage :parentCode="parentCode" :functionPermission ="functionPermission"/>
    </el-tab-pane>
  </el-tabs>
    </div>

  </div>
</template>

<script>
import TablePage from './table';
import MenuPage from './menu_table';
import ApprovalPage from './approval/table.vue';

export default {
  name: 'List',
  components: {
    TablePage,
    MenuPage,
    ApprovalPage,
  },
  data() {
    return {
      parentCode: '',
      functionPermission: '',
      activeName: 'page',
    };
  },
  created() {},
  methods: {
    changeParentCode(val) {
      console.log(val);
      this.parentCode = val.parentCode;
      this.functionPermission = val.functionPermission;
    },
  },
};
</script>

<style lang="less" scoped>
.list-box {
  display: flex;
  .main{
    width: 50%;
    // height: 600px;
  }
  .left{
    padding-right: 20px;
  }
  .right{
    padding-left: 20px;
    .table{
      height: 600px;
    }
  }
}
</style>
