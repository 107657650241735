export default {
  toolBarConfig: [
    {
      type: 'button',
      buttonType: 'primary',
      icon: 'el-icon-plus',
      code: 'btnConfig-add',
      name: '添加按钮',
    },
  ],
  columns: [
    {
      field: 'buttonName',
      title: '按钮名称',
      treeNode: true,
    },
    {
      field: 'buttonCode',
      title: '按钮编码',
    },
    {
      field: 'buttonType',
      title: '按钮类型',
      formatter: ({ cellValue }) => (cellValue === '0' ? '头部按钮' : '行内操作按钮'),
    },
    {
      field: 'buttonIcon',
      title: '按钮图标',
    },
    {
      title: '操作',
      width: '120',
      align: 'center',
      buttons: [
        {
          type: 'button',
          buttonType: 'primary',
          name: '编辑',
          code: 'btnConfig-edit',
          props: {
            type: 'primary',
          },
        },
        {
          type: 'button',
          buttonType: 'primary',
          code: 'btnConfig-delete',
          name: '删除',
          props: {
            type: 'primary',
          },
        },
      ],
    },
  ],
  tableConfig: {
    border: true,
    treeConfig: {
      children: 'children',
    },
    idKey: 'buttonCode', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
