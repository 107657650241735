<template>
  <div class="container">
    <div class="title-container">
      <div class="title">业务数据判断条件</div>
      <el-dropdown @command="handleCommand">
        <el-button type="primary">
          添加一组<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item,index) in fieldType" :key="index" :command="item.dictCode">{{item.dictValue}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="form-container">
      <div class="form-item" v-for="(item,index) in approvalData" :key="index">
        <el-button @click="delFormItem(index)" class="del-btn" icon="el-icon-delete" circle></el-button>
       <DictForm :ref="'form' + index" v-if="item.type==='dict'" :formdata = 'item'/>
       <CustomForm :ref="'form' + index" v-if="item.type==='custom'" :formdata = 'item' />
      </div>
    </div>
  </div>
</template>

<script>
import DictForm from './dict_form.vue';
import CustomForm from './custom_form.vue';
import request from '../../../../../../../../utils/request';

export default {
  components: {
    DictForm,
    CustomForm,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      approvalData: [],
      fieldType: [], // 数据类型
      dictItem: {
        type: 'dict',
        fieldCode: '', // 字段编码
        fieldName: '', // 字段名称
        fieldType: '数据字典',
        fieldDataSource: '',
        fieldKeywords: '',
        compareType: '',
        controlFlag: true,
      },
      customItem: {
        type: 'custom',
        fieldCode: '', // 字段编码
        fieldName: '', // 字段名称
        fieldType: '自定义',
        fieldKeywords: '',
        compareType: '',
        controlFlag: true,
      },
    };
  },
  watch: {
    value(val) {
      this.approvalData = val;
      this.approvalData.map((v) => {
        const item = v;
        if (item.fieldType === 'design') {
          item.type = 'custom';
          item.fieldType = '自定义';
        }
        if (item.fieldType === 'dictionary') {
          item.type = 'dict';
          item.fieldType = '数据字典';
          item.fieldKeywords = item.fieldKeywords.split(',');
        }
        item.controlFlag = item.controlFlag === '1' || item.controlFlag === true;
        return item;
      });
      console.log(this.approvalData);
    },
  },
  created() {
    this.getfieldType();
  },
  methods: {
    getFormData() {
      const data = [];
      this.approvalData.forEach((item, index) => {
        if (this.$refs[`form${index}`]) {
          data.push(this.$refs[`form${index}`][0].getFormData());
        }
      });
      return data;
    },
    /**
     * 获取数据类型数据字典
     */
    getfieldType() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'mdm_approval_field_type',
      }).then((res) => {
        if (res.success) {
          this.fieldType = res.result;
        }
      });
    },
    handleCommand(command) {
      console.log(command);
      if (command === 'dictionary') {
        this.approvalData.push(this.dictItem);
      } else if (command === 'design') {
        this.approvalData.push(this.customItem);
      } else {
        this.$message.warning('暂未开发此功能');
      }
    },
    /**
     * 删除一行数据
     */
    delFormItem(index) {
      this.approvalData.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .title-container {
    display: flex;
    align-items: center;
    .title {

      font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
      margin-right: 10px;
    }
  }
  .form-container{
    margin-top: 20px;
    .form-item{
      display: flex;
      align-items: center;
    }
  }
  .del-btn{
    width: 30px;
    height: 30px;
  }
}
</style>
