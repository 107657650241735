<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import BusinessData from './components/business_data/approval_conditions';
import MatchObject from './components/match_object/match_object';

formCreate.component('BusinessData', BusinessData);
formCreate.component('MatchObject', MatchObject);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'businessCode',
          title: '业务编码',
          props: {
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'input',
          field: 'businessName',
          title: '业务名称',
          props: {
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'switch',
          field: 'controlFlag',
          title: '开关',
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'FormSelectTreeNew',
          field: 'menuCode',
          title: '菜单',
          props: {
            restful: '/mdm/baseTreeController/menuTree',
            props: {
              parent: 'parentCode', // 父级唯一标识
              value: 'code', // 唯一标识
              label: 'name', // 标签显示
              children: 'children', // 子级
            },
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'select',
          field: 'approvalType',
          title: '审批类型',
          dictCode: 'mdm_approval_type',
          props: {
            clearable: true,
            loading: false,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'select',
          field: 'controlScope',
          title: '控制范围',
          dictCode: 'mdm_approval_control_scope',
          props: {
            clearable: true,
            loading: false,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'select',
          field: 'groupCheckWay',
          title: '条件判断逻辑',
          dictCode: 'mdm_approval_group_check_way',
          props: {
            clearable: true,
            loading: false,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'input',
          field: 'defaultWorkFlowKey',
          title: '默认流程key',
          props: {
            clearable: true,
          },
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'input',
          field: 'remarks',
          props: {
            clearable: true,
          },
          title: '备注',
          col: {
            md: {
              span: 8,
            },
          },
        },
        {
          type: 'BusinessData',
          field: 'conditionList',
          title: '',
          col: {
            md: {
              span: 24,
            },
          },
        },
        {
          type: 'MatchObject',
          field: 'submitterList',
          title: '',
          col: {
            md: {
              span: 24,
            },
          },
        },
      ],
      // formFunctionCode: 'mdm_approval_config_form',
      // formParentCode: 'CRM20210428000001593',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.hiddenFields(true, 'menuCode');
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/mdm/mdmApprovalConfigController/queryForUpdate', { id: this.formConfig.id }).then((res) => {
          if (res.success) {
            const data = res.result;
            data.controlFlag = data.controlFlag === '1';
            // data.conditionList = data.conditionList.map((item) => ({
            //   ...item,
            //   controlFlag: item.controlFlag === '1',
            //   fieldKeywords: item.fieldKeywords.split(','),
            // }));
            const fieldKeywords = (item) => {
              if (item.submitterType !== 'org') {
                if (!item.fieldKeywords) {
                  return [];
                }
                return item.fieldKeywords.split(',');
              }
              return item.fieldKeywords;
            };
            data.submitterList = data.submitterList.map((item) => ({
              ...item,
              controlFlag: item.controlFlag === '1',
              fieldKeywords: fieldKeywords(item),
            }));
            console.log(data.submitterList);
            this.setValue(res.result);
          }
        });
      }
    });
  },
  methods: {
    // 对rule进行处理
    beforeSetRule(rule) {

    },
    // 单个rule处理
    setRule(v) {
      const item = v;
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      // 获取业务数据判断条件数据
      let conditionList = this.fApi.method('conditionList', 'getFormData')();
      conditionList = conditionList.map((item) => ({
        ...item,
        controlFlag: item.controlFlag ? '1' : '0',
        fieldType: item.fieldType === '自定义' ? 'design' : 'dictionary',
        fieldKeywords: Array.isArray(item.fieldKeywords) ? item.fieldKeywords.join(',') : item.fieldKeywords,
      }));
      // 获取提交人判断条件
      let submitterList = this.fApi.method('submitterList', 'getFormData')();
      submitterList = submitterList.map((item) => ({
        ...item,
        controlFlag: item.controlFlag ? '1' : '0',
        fieldKeywords: Array.isArray(item.fieldKeywords) ? item.fieldKeywords.join(',') : item.fieldKeywords,
      }));
      formData.conditionList = conditionList;
      formData.submitterList = submitterList;
      console.log(formData);
      formData.controlFlag = formData.controlFlag ? '1' : '0';
      if (formData) {
        let url = '/mdm/mdmApprovalConfigController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/mdm/mdmApprovalConfigController/update';
          params.id = this.formConfig.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
