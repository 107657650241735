<script>
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  name: 'BtnConfigForm',
  data() {
    const self = this;
    return {
      rule: [
        {
          type: 'select',
          field: 'buttonName',
          title: '按钮名称',
          value: this.formConfig && this.formConfig.buttonName,
          // refresh: true,
          restful: '/mdm/mdmbutton/listCondition',
          optionsKey: {
            label: 'buttonName',
            value: 'buttonName',
          },
          props: {
            ...this.props,
            filterable: true,
            remote: true,
            remoteParams: 'buttonName',
          },
          on: {
            ...this.on,
            change: (e) => {
              const selectData = this.getRule('buttonName').options.find(
                (item) => item.buttonName === e,
              );
              // if (selectData.buttonCode === 'import') {
              //   self.hiddenFields(false, ['configCode']);
              // } else {
              //   self.hiddenFields(true, ['configCode']);
              // }
              this.setValue({
                buttonCode: selectData.buttonCode,
                buttonType: selectData.buttonType,
                buttonIcon: selectData.iconStyle,
              });
            },
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择按钮',
            },
          ],
        },
        {
          type: 'input',
          field: 'buttonCode',
          title: '按钮编码',
          value: this.formConfig && this.formConfig.buttonCode,
          props: {
            disabled: true,
          },
        },
        {
          type: 'select',
          field: 'buttonType',
          title: '按钮类型',
          value: this.formConfig && this.formConfig.buttonType,
          // refresh: true,
          props: {
            placeholder: '请选择按钮位置',
            disabled: true,
            loading: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择按钮位置',
            },
          ],
          options: [
            {
              value: '0',
              label: '头部按钮',
            },
            {
              value: '1',
              label: '行内操作按钮',
            },
          ],
        },
        {
          type: 'FormButton',
          field: 'buttonIcon',
          title: '按钮图标',
          value: this.formConfig && this.formConfig.buttonIcon,
          props: {
            icon: this.formConfig && this.formConfig.buttonIcon,
            disabled: true,
            type: 'text',
          },
        },
        {
          type: 'select',
          field: 'buttonOperationType',
          title: '操作类型',
          value: this.formConfig && this.formConfig.buttonOperationType,
          // refresh: true,
          props: {
            placeholder: '请选择操作类型',
            disabled: false,
            loading: false,
            clearable: true, // 是否显示清空按钮
          },
          on: {
            change(value) {
              console.log(value, '____________');
              if (Number(value) === 3) {
                console.log(self);
                self.hiddenFields(false, ['doCode']);
              } else {
                self.hiddenFields(true, ['doCode']);
              }
              if (Number(value) === 2) {
                self.hiddenFields(false, ['ask']);
              } else {
                self.hiddenFields(true, ['ask']);
              }
            },
          },
          options: [
            {
              value: '1',
              label: '简单',
            },
            {
              value: '2',
              label: '询问',
            },
            {
              value: '3',
              label: '弹窗',
            },
          ],
        },
        {
          type: 'input',
          field: 'doCode',
          title: '功能编码',
          value: this.formConfig && this.formConfig.doCode,
          visible: this.formConfig.buttonOperationType !== 3,
          // refresh: true,
          props: {
            hidden: true,
            placeholder: '请输入功能编码',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'configCode',
          title: '导入编码',
          value: this.formConfig && this.formConfig.configCode,
          visible: this.formConfig.buttonCode !== 'import',
          // refresh: true,
          props: {
            hidden: true,
            placeholder: '请输入导入编码',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'apiUrl',
          title: '提交apiUrl',
          value: this.formConfig && this.formConfig.apiUrl,
          // refresh: true,
          props: {
            placeholder: '请输入apiUrl',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'queryUrl',
          title: '查询queryUrl',
          value: this.formConfig && this.formConfig.queryUrl,
          visible: this.formConfig.buttonIcon !== 'el-icon-edit',
          // refresh: true,
          props: {
            placeholder: '请输入queryUrl',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'ask',
          title: '询问语',
          value: this.formConfig && this.formConfig.ask,
          // refresh: true,
          visible: this.formConfig.buttonOperationType !== 2,
          props: {
            hidden: true,
            placeholder: '请输入询问语',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'switch',
          field: 'visible',
          title: '是否显示',
        },
        {
          type: 'inputNumber',
          field: 'buttonOrder',
          title: '排序',
          value: this.formConfig && this.formConfig.buttonOrder,
          refresh: true,
          props: {
            hidden: true,
            // placeholder: '请输入询问语',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
      ],
    };
  },
  watch: {
    formConfig: {
      deep: true,
      immediate: true,
      handler(v) {
        this.init();
      },
    },
  },
  mounted() {
  },
  methods: {
    async init() {
      console.log(this.formConfig);
      if (this.formConfig.id) {
        const res = await request.get('/mdm/mdmfunctionsub/buttonQuery', { id: this.formConfig.id });
        console.log(res);
        this.$nextTick(() => {
          this.setValue(res.result);
        });
      }
    },
    submit() {
      const formData = this.getFormData();
      // this.$emit('onGetList');
      if (formData) {
        let url = '';
        let params = {};
        url = '/mdm/mdmfunctionsub/buttonSetUp';
        params = {
          ...formData,
          parentCode: this.formConfig.parentCode,
          functionCode: this.formConfig.functionCode,
        };

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            // this.closeModal();
            // this.getList();
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
