var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("Table", { ref: "table", attrs: { propsObj: _vm.propsObj } }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-check" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.closeModal },
            },
            [_vm._v("关闭 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }