<script>
import TablePage from '../../../../../components/table_page';
// import configs from './data';
import { Form, TableConfig } from '../form';
import btnConfig from '../btnConfig';
import permissions from '../permissions';
// import request from '../../../../../utils/request';

const functionType = [
  {
    value: '1',
    label: '列表',
  },
  {
    value: '2',
    label: '表单',
  },
];

export default {
  extends: TablePage,
  components: {
    Form,
    TableConfig,
    btnConfig,
    permissions,
  },
  props: {
    parentCode: {
      type: String,
      default: '',
    },
    functionPermission: String,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmfunctionsub/findSubPage',
      // isCalculateHeight: false,
      params: { parentCode: '' },
      configs: {
        // tableConfig: {
        //   idKey: 'functionCode', // 默认勾选判断字段
        // },
      },
      tableHeight: '100',
      formConfig: {},
      propsObjInData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
      copyToolBarConfig: [],
    };
  },
  watch: {
    parentCode(n) {
      this.params.parentCode = n;
      this.formConfig.parentCode = n;
      this.getList();
    },
  },
  async created() {
    const columns = await this.getConfigList('listnexttable');

    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'functionType') {
        rowData.formatter = ({ cellValue }) => (functionType.find((a) => a.value === cellValue) || {}).label;
      }
      return rowData;
    });
    this.configs.columns = columns;
  },
  methods: {
    setPageHeight() {
      this.$nextTick(() => {
        this.searchOpenHeight = this.$refs.search && this.$refs.search.getClientRects()[0] ? this.$refs.search.getClientRects()[0].height - 44 : 0;
        this.isModalTable = false;
        const pageHeight = this.$refs.page.getClientRects() && this.$refs.page.getClientRects()[0] && this.$refs.page.getClientRects()[0].height;
        this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
      });
    },
    afterGetList() {
      if (this.configs.toolBarConfig && this.configs.toolBarConfig.length > 0) {
        this.copyToolBarConfig = this.configs.toolBarConfig;
      }
      if (this.functionPermission === '0') {
        this.configs.toolBarConfig = [];
      } else {
        this.configs.toolBarConfig = this.copyToolBarConfig;
      }
    },
    // getConfigList() {
    //   request.post('/mdm/mdmtableconfig/query', { functionCode: 'listnexttable' }).then((res) => {
    //     if (res.success) {
    //       const columns = [];
    //       res.result.column.map((v) => {
    //         const rowData = v;
    //         if (rowData.field === 'functionType') {
    //           rowData.formatter = ({ cellValue }) => (functionType.find((a) => a.value === cellValue) || {}).label;
    //         }
    //         columns.push(rowData);
    //         return rowData;
    //       });
    //       this.configs.columns = this.configs.columns.concat(columns);
    //       // 设置按钮
    //       this.setButton(res.result.buttonVos);
    //     }
    //   });
    // },
    beforeGetList() {
      return !!this.params.parentCode;
    },
    modalClick({ val, row }) {
      console.log('22222222222222222222', row);
      if (val.code === 'add' || val.code === 'edit') {
        this.formName = 'Form';
        if (val.code === 'add') {
          if (!this.parentCode) {
            this.$message.error('请先选择菜单');
            return false;
          }
          this.formConfig.type = 'add';
          this.formConfig.parentCode = this.parentCode;
          this.modalConfig.title = '新增';
        }
        if (val.code === 'edit') {
          // if (this.selection.length === 0) {
          //   this.$message.error('请先选择数据');
          //   return false;
          // }
          this.formConfig.type = 'edit';
          this.modalConfig.title = '编辑';
          this.formConfig.row = row.id;
          this.formConfig.parentCode = this.parentCode;
        }
        this.openDrawer();
      } else if (val.code === 'config') {
        this.formName = 'TableConfig';
        this.modalConfig.title = '配置';
        this.formConfig.functionCode = row && row.functionCode;
        this.formConfig.functionType = row && row.functionType;
        this.formConfig.parentCode = row && row.parentCode;
        this.openFull();
      } else if (val.code === 'button_config') {
        this.formName = 'btnConfig';
        this.modalConfig.title = '按钮配置';
        this.propsObjInData.functionCode = row && row.functionCode;
        this.propsObjInData.parentCode = row && row.parentCode;
        this.openModal();
      } else if (val.code === 'permission_obj') {
        this.formName = 'permissions';
        this.modalConfig.title = '权限对象';
        this.propsObjInData.functionCode = row && row.functionCode;
        this.propsObjInData.parentCode = row && row.parentCode;
        this.openDrawer();
      }
      return true;
    },
  },
};
</script>
