var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-box" }, [
    _c(
      "div",
      { staticClass: "main left home" },
      [_c("MenuPage", { on: { changeParentCode: _vm.changeParentCode } })],
      1
    ),
    _c(
      "div",
      { staticClass: "main right home" },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "页面配置", name: "page" } }, [
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c("TablePage", {
                    attrs: {
                      parentCode: _vm.parentCode,
                      functionPermission: _vm.functionPermission,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "el-tab-pane",
              { attrs: { label: "审批配置", name: "approval" } },
              [
                _c("ApprovalPage", {
                  attrs: {
                    parentCode: _vm.parentCode,
                    functionPermission: _vm.functionPermission,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }