<script>
import Form from '../../../../../../../../components/form';

export default {
  extends: Form,
  props: {
    formdata: {
      type: Object,
      default: () => ({
        fieldCode: '',
        fieldName: '',
        fieldType: '自定义',
        fieldKeywords: '',
        compareType: '',
        controlFlag: true,
      }),
    },
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      },
    };
  },
  created() {
    this.rule = [{
      type: 'input',
      field: 'fieldCode',
      title: '字段编码',
      value: this.formdata.fieldCode,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'input',
      field: 'fieldName',
      title: '字段名称',
      value: this.formdata.fieldName,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'input',
      field: 'fieldType',
      title: '字段类型',
      value: this.formdata.fieldType,
      props: {
        disabled: true,
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'input',
      field: 'fieldKeywords',
      title: '字段值',
      value: this.formdata.fieldKeywords,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'select',
      field: 'compareType',
      title: '比较类型',
      dictCode: 'mdm_approval_field_compare_type',
      value: this.formdata.compareType,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'switch',
      field: 'controlFlag',
      title: '启用状态',
      value: this.formdata.controlFlag,
      col: {
        md: {
          span: 8,
        },
      },
    }];
  },
};
</script>

<style>

</style>
