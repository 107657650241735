<script>
import TablePage from '../../../../../components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmfunctionsub/objList',
      params: {
        parentCode: this.propsObj.parentCode,
        functionCode: this.propsObj.functionCode,
      },
      isCalculateHeight: false,
    };
  },
  watch: {
    tableData(v) {
      if (v.length > 0) {
        this.selectRow = v.filter((item) => item.select === '1');
        this.selection = this.selectRow.map((item) => item.permissionObjCode);
        this.setSelectData();
      }
    },
  },

  created() {
    this.configs.tableConfig.idKey = 'permissionObjCode';
    this.getConfigList('list_permissions');
  },
  methods: {
  },
};
</script>
