<script>
import TableForm from '../../../../../../components/table_form';

const columns = [
  {
    fixed: 'left', // 固定位置在左边
    type: 'radio', // 多选
    title: null,
    width: '50',
  },
  {
    field: 'className',
    title: '类名称',
    editRender: {
      name: 'elInput',
      // props: {
      //   readonly: true,
      // },
    },
  },
  {
    field: 'classPath',
    title: '类路径',
    editRender: {
      name: 'elInput',
      props: {
        readonly: true,
      },
    },
  },
];
const tableConfig = {
  border: true,
  idKey: 'id', // 默认勾选判断字段
  resizable: true, // 所有的列是否允许拖动列宽调整大小
  showOverflow: true, // 当内容过长时显示为省略号
  keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
  highlightHoverRow: true, // 鼠标滑动高亮

  height: '600',
};

export default {
  extends: TableForm,
  data() {
    return {
      tableData: [],
      configs: {
        // 配置信息
        columns, // 列信息
        tableConfig, // 表信息
      },
    };
  },
  created() {},
  methods: {
    onInput({
      val, item, rowIndex, row,
    }) {
      return {
        val,
        item,
        rowIndex,
        row,
      };
    },
    // 点击表格触发编辑模式
    editActivedEvent({ rowIndex, row, columnIndex }) {
      // 假设第一行的字段为functionName的数据不予许编辑
      if (rowIndex === 0) {
        this.configs.columns.map((v) => {
          const item = v;
          if (v.field === 'functionName') {
            item.disabled = true;
          }
          return item;
        });
      } else {
        this.configs.columns[columnIndex].disabled = false;
      }
      return { rowIndex, row };
    },

    beforeRadioChange({ row }) {
      this.$emit('change', row);
      return true;
    },
  },
};
</script>
