<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import listTableForm from './components';

formCreate.component('listTableForm', listTableForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'listTableForm',
          field: 'list',
          title: '',
          props: {
            functionCode: '',
            selection: [],
            idKey: 'id',
          },
          value: {
            tableData: [],
          },
        },
      ],
    };
  },
  mounted() {
    this.updateRules({
      list: {
        props: {
          functionCode: this.formConfig.functionCode,
          functionType: this.formConfig.functionType,
        },
      },
    });
    this.getList();
  },
  methods: {
    getList() {
      request
        .post('/mdm/mdmcolumnconfig/list_condition', {
          functionCode: this.formConfig.functionCode,
          parentCode: this.formConfig.parentCode,
        })
        .then((res) => {
          if (res.success) {
            // 用双向绑定设置数据
            console.log(res.result);
            res.result.map((v) => {
              const rowData = v;
              rowData.customName = v.type;
              rowData.editableInCreate = rowData.editableInCreate === null ? 'true' : rowData.editableInCreate;
              rowData.editableInEdit = rowData.editableInEdit === null ? 'true' : rowData.editableInEdit;
              rowData.visibleInEdit = rowData.visibleInEdit === null ? 'true' : rowData.visibleInEdit;
              rowData.visibleInLook = rowData.visibleInLook === null ? 'true' : rowData.visibleInLook;
              return rowData;
            });
            this.setValue({
              list: { tableData: res.result },
            });
          }
        });
    },
    // 提交
    submit() {
      let params = [];
      this.getFormData().list.tableData.forEach((v) => {
        const rowData = v;
        if (rowData.type === 'custom') {
          rowData.type = rowData.customName;
        }
        rowData.functionCode = this.formConfig.functionCode;
        rowData.id = rowData.id.indexOf('row_') === '-1' ? rowData.id : '';
        params.push(rowData);
      });
      params = params.map((v) => {
        const item = v;
        if (!item.visible) {
          item.visible = false;
        }
        if (!item.editableInCreate) {
          item.editableInCreate = 'false';
        }
        if (!item.editableInEdit) {
          item.editableInEdit = 'false';
        }
        if (!item.visibleInEdit) {
          item.visibleInEdit = 'false';
        }
        if (!item.visibleInLook) {
          item.visibleInLook = 'false';
        }
        return item;
      });
      const submitParams = {
        parentCode: this.formConfig.parentCode,
        functionCode: this.formConfig.functionCode,
        mdmColumnConfigVos: params,
      };
      console.log(this.formConfig.functionType);
      console.log(params);
      const showParams = params.filter((item) => item.visible);
      if (!showParams.every((item) => item.type) && this.formConfig.functionType === '2') {
        this.$message.error('每个字段都应选择控件类型，自定义控件还要填写控件名称');
        return false;
      }
      // console.log(JSON.stringify(submitParams));
      request.post('/mdm/mdmcolumnconfig/save_batch', submitParams).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
