<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import { NextTableForm, NextTableFieldForm } from './components';

formCreate.component('NextTableForm', NextTableForm);
formCreate.component('NextTableFieldForm', NextTableFieldForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'NextTableForm',
          field: 'menu',
          title: '',
          col: {
            md: { span: 11 },
          },
          props: {
            selectionList: [],
            idKey: 'id',
          },
          on: {
            change: (val) => this.checkClassPath(val),
          },
          value: {
            tableData: [],
          },
        },
        {
          type: 'NextTableFieldForm',
          field: 'code',
          title: '',
          col: {
            md: { span: 11, offset: 2 },
          },
          props: {
            selectionList: [],
            idKey: 'field',
          },
          value: {
            tableData: [],
          },
        },
      ],
    };
  },
  mounted() {
    // this.updateRules({ code: { props: { selectionList: this.formConfig.selection } } });
    this.getList();
  },
  methods: {
    submit() {
      const selectData = [];
      this.getFormData().code.tableData.forEach((v) => {
        if (v.checked) {
          const rowData = v;
          selectData.push(rowData);
        }
      });
      this.$emit('onClose');
      this.$emit('onGetSelect', selectData);
    },
    getList() {
      request.post('/mdm/mdmcolumnconfig/column_resolve_select').then((res) => {
        if (res.success) {
          // 用双向绑定设置数据
          this.setValue({
            menu: { tableData: res.result },
          });
        }
      });
    },
    checkClassPath(val) {
      request.get('/mdm/mdmcolumnconfig/column_resolve', { classPath: val.classPath }).then((res) => {
        if (res.success) {
          // 用双向绑定设置数据
          this.setValue({
            code: { tableData: res.result },
          });
        }
      });
    },
  },
};
</script>
