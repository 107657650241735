<script>
import TablePage from '../../../../../components/table_page';
import configs from './data';
import * as Form from '../form';
import Modal from '../../../../../components/modal';
// import request from '../../../../../utils/request';

export default {
  extends: TablePage,

  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmfunction/pageEngineTree',
      // isCalculateHeight: false,
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('123');
  },
  mounted() {

  },
  methods: {
    setPageHeight() {
      this.$nextTick(() => {
        this.searchOpenHeight = this.$refs.search && this.$refs.search.getClientRects()[0] ? this.$refs.search.getClientRects()[0].height - 44 : 0;
        this.isModalTable = false;
        const pageHeight = this.$refs.page.getClientRects() && this.$refs.page.getClientRects()[0] && this.$refs.page.getClientRects()[0].height;
        this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight + 40;
      });
    },
    /**
     * 获取数据后置
     */
    afterGetList() {
      console.log(this.searchFormData);
      console.log(this.tableData);
      const searchKes = Object.keys(this.searchFormData);
      const hasSearchData = searchKes.some((item) => this.searchFormData[item]);
      const tempConfigs = JSON.parse(JSON.stringify(this.configs));

      if (hasSearchData) {
        this.$nextTick(() => {
          this.$refs.xTable.setAllTreeExpand(true);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.xTable.clearTreeExpand();
        });
      }
      this.configs = tempConfigs;
      return true;
    },
    beforeRadioChange({ row }) {
      this.$emit('changeParentCode', { parentCode: row.functionCode, functionPermission: row.functionPermission });
      return true;
    },
    setColumn(item) {
      const copyItem = item;
      if (copyItem.field === 'functionCode') {
        copyItem.treeNode = true;
      }
      // if (copyItem.field === 'checkTyperadio') {
      //   copyItem.type = ({ row }) => (row.functionPermission === '0' ? '' : 'radio');
      // }
      return copyItem;
    },
  },
};
</script>
