<script>
import TablePage from '../../../../../components/table_page';
import configs from './table/data';
import Modal from '../../../../../components/modal';
import BtnConfigForm from './form';

export default {
  extends: TablePage,
  components: {
    Modal,
    BtnConfigForm,
  },
  props: {
    propsObj: Object,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmfunctionsub/buttonList',
      params: {
        functionCode: this.propsObj.functionCode,
        parentCode: this.propsObj.parentCode,
      },
      configs,
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增按钮',
        visible: false,
        type: '',
      },
      formName: 'BtnConfigForm',
      isCalculateHeight: false,
    };
  },
  created() {
    console.log(this.propsObj);
    this.params = {
      functionCode: this.propsObj.functionCode,
      parentCode: this.propsObj.parentCode,
    };
    // const url = '/mdm/mdmfunctionsub/buttonList';
    // const params = { functionCode };
    // request.post(url, params).then((res) => {
    //   if (res.success) {
    //     if (Array.isArray(res.result.data)) this.tableData = res.result.data;
    //   }
    // });
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'BtnConfigForm';
      switch (val.code) {
        case 'btnConfig-add':
          this.formConfig = { code: val.code, ...row };
          this.formConfig.functionCode = this.propsObj.functionCode;
          this.formConfig.parentCode = this.propsObj.parentCode;
          this.modalConfig.title = '新增';
          this.formName = 'BtnConfigForm';
          this.openModal();
          break;
        case 'btnConfig-edit':
          console.log(row);
          this.formConfig = { ...val, ...row };
          this.modalConfig.title = '编辑';
          this.formName = 'BtnConfigForm';
          this.openModal();
          break;
        case 'btnConfig-delete':
          this.messageBox({
            title: '是否删除',
            url: '/mdm/mdmfunctionsub/buttonRemove',
            msg: '删除',
            params: [row.id],
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
