export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [],
  },
  columns: [],
  tableConfig: {
    border: true,
    treeConfig: {
      children: 'children',
    },
    idKey: 'functionCode', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
  },
};
