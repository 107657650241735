<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'functionCode',
          title: '编码',
          value: '',
        },
        {
          type: 'input',
          field: 'functionName',
          title: '名称',
          value: '',
        },
        {
          type: 'select',
          field: 'functionType',
          title: '类型',
          value: '',
          options: [
            {
              label: '列表',
              value: '1',
            },
            {
              label: '表单',
              value: '2',
            },
          ],
        },
        {
          type: 'input',
          field: 'apiUrl',
          title: '请求地址',
          props: {
            placeholder: '请输入请求地址',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'remarks',
          title: '备注',
          props: {
            type: 'textarea',
          },
          value: '',
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.initEditValue();
  },
  methods: {
    // 提交
    async submit() {
      console.log(this.formConfig);
      const submitType = this.formConfig.type;
      let path = '';
      if (submitType === 'add') {
        path = '/mdm/mdmfunctionsub/save';
      } else {
        path = '/mdm/mdmfunctionsub/update';
      }
      const formData = { ...this.getFormData(), parentCode: this.formConfig.parentCode, id: this.formConfig.row };
      request.post(path, formData).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
    // 编辑赋值
    initEditValue() {
      const submitType = this.formConfig.type;
      if (submitType === 'add') {
        this.resetFields();
      } else if (this.formConfig.row) {
        request
          .get('/mdm/mdmfunctionsub/query', { id: this.formConfig.row })
          .then((res) => {
            this.setValue(res.result);
          });
      }
    },
  },
};
</script>
